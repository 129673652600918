<template>
  <div>
    <div
      class="
        w-full
        bg-white
        border border-solid border-gray-200
        rounded-none
        lg:rounded-cu
        h-auto
        xl:h-272
      "
    >
      <!--  style="box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1)"  -->
      <div class="flex items-start xl:items-center pt-5">
        <img src="/svg/agendaCir.svg" class="h-20 xl:h-32" />
        <div class="flex flex-col ml-5">
          <span class="text-lg font-EffraR">
            {{ $t("patient.certified_doctors") }}
          </span>
          <div class="text-base xl:text-3xl font-EffraM w-4/5 xl:w-3/5 mb-10">
            {{ $t("patient.reservez_une_consultation") }}
          </div>
        </div>
      </div>
      <div
        class="
          flex flex-wrap
          items-start
          px-5
          xl:px-0
          gap-y-4
          xl:gap-y-0
          pb-5
          justify-center
        "
      >
        <div id="city" class="w-4/5 xl:w-1/5 relative order-2 xl:order-1">
          <multiselect
            :searchable="true"
            :internal-search="true"
            :preserve-search="true"
            :multiple="false"
            v-model="cityMulti"
            :options="citys"
            placeholder="Ville"
            track-by="name"
            label="name"
            @input="onChangeCity"
            class="dokmultiselect"
          ></multiselect>
        </div>
        <div
          id="tirm"
          class="w-full xl:w-6/12 xl:ml-2 relative order-1 xl:order-2"
        >
          <input
            type="text"
            :placeholder="$t('patient.placeholder_input_card_search_doctor')"
            class="
              bg-white
              border border-solid border-gray-200
              py-4
              w-full
              rounded-cu
              px-2
              text-lg
              pl-8
              h-16
              flex
              items-center
              justify-center
            "
            :class="data.length ? 'rounded-b-none' : ''"
            v-model="query"
          />
          <span
            class="
              absolute
              left-0
              top-0
              text-gray-500
              h-full
              flex
              items-center
              justify-center
              px-2
            "
          >
            <i class="fas fa-search"></i>
          </span>

          <!-- Results -->
          <div
            class="
              absolute
              bg-white
              w-full
              shadow
              border-0 border-t-0 border-solid border-gray-200
              py-0
              max-h-300
              overflow-y-auto
            "
            v-if="data.length"
          >
            <div
              v-for="doctor in data"
              :key="doctor.objectID"
              class="
                border-0 border-b border-solid border-gray-200
                py-2
                px-5
                hover:bg-gray-100
                cursor-pointer
              "
              @click="goToDoctorProfile(doctor)"
            >
              <div class="flex flex-row items-start">
                <div class="mb-3 lg:mb-0">
                  <div class="h-10 w-10 overflow-hidden rounded-cu">
                    <img
                      :src="$checkAvatar(doctor.image)"
                      onerror="javascript:this.src='/no-avatar.png'"
                      class="object-cover h-10 w-full"
                    />
                  </div>
                </div>
                <div class="ml-3 flex flex-col flex-1 mb-3">
                  <h2 class="text-lg font-EffraR">
                    {{ doctor.title || "Dr." }} {{ doctor.firstName }}
                    {{ doctor.lastName }}
                  </h2>
                  <div
                    class="text-dark text-base font-EffraM my-1"
                    v-if="doctor.specialities && doctor.specialities.length"
                  >
                    {{ doctor.specialities[0].name }}
                  </div>
                  <div class="address">
                    <h5 class="font-EffraR text-dokBlue-ultra">
                      {{ doctor.cityName }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="button" class="w-1/5 xl:ml-2 order-3 px-2 xl:px-0">
          <button
            class="
              bg-rosedok
              text-white
              font-EffraM
              text-lg
              border-0
              rounded-cu
              px-3
              py-4
              w-full
              h-16
              flex
              items-center
              justify-center
              cursor-pointer
            "
            @click="onSearch"
          >
            <i class="fas fa-search xl:mr-2"></i>
            <span class="hidden xl:block uppercase">
              {{ $t("patient.search") }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { get, kebabCase } from "lodash";
export default {
  data() {
    return {
      searchClient: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      ),
      query: "",
      cityMulti: null,
      city: null,
      citys: [],
      data: [],
    };
  },
  watch: {
    query(newVal, oldVal) {
      if (!newVal.length) {
        this.data = [];
      }
      this.onAlgoliaCall();
    },
  },
  created() {
    this.getAllCitys();
  },
  methods: {
    goToDoctorProfile(doctor) {
      const speciality = kebabCase(
        get(doctor, "specialities[0].name", "medecin-generaliste")
      ).toLowerCase();
      const city = kebabCase(get(doctor, "cityName", "maroc")).toLowerCase();
      this.$router.push({
        name: "profileDoctor",
        params: { slug: doctor.slug, speciality, city },
      });
    },
    onChangeCity(value) {
      this.city = value ? value.name : null;
    },
    async getAllCitys() {
      const index = this.searchClient.initIndex(
        "dokma_dev_cities_principal_first"
      );

      const data = await index.search("", {
        hitsPerPage: 618,
      });

      this.citys = data.hits;
    },
    onAlgoliaCall() {
      let index_search = this.searchClient.initIndex("dokma_doctors");
      index_search.search(this.query).then(({ hits }) => {
        if (hits.length) {
          this.data = hits;
        }
      });
    },
    onSearch() {
      let query = {};

      if (this.city) query.city = this.city.toLowerCase();

      if (this.query) {
        query.query = this.query.toLowerCase();
        if (!this.city) {
          query.city = "maroc";
        }
      }

      this.$router.push({
        name: "doctorsList",
        params: {
          ...query,
        },
      });
    },
  },
};
</script>

<style>
.dokmultiselect .multiselect__tags {
  height: 56px !important;
}

.dokmultiselect .multiselect__tags {
  @apply border border-solid border-gray-200;
}

.dokmultiselect .multiselect__select {
  height: 48px !important;
}

.dokmultiselect .multiselect__placeholder {
  padding-top: 8px !important;
  @apply text-gray-600;
}

.dokmultiselect .multiselect__input,
.dokmultiselect .multiselect__single {
  line-height: 38px;
  @apply font-EffraR;
}

.dokmultiselect .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.dokmultiselect .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>
